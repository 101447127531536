import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p className="text-chocolate">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (_node, children) => (
      <span className="font-display text-chocolate text-2xl sm:text-3xl">
        {children}
      </span>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <span className="font-display text-chocolate text-xl sm:text-2xl">
        {children}
      </span>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <span className="font-display text-chocolate text-lg sm:text-xl">
        {children}
      </span>
    ),
    'embedded-asset-block': (node) => {
      console.log(node.data.target);
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return <GatsbyImage image={getImage(gatsbyImageData)} />;
    },
  },
};

export default function Article({ data }) {
  const { body, title, description, mainImage } = data.contentfulArticle;

  return (
    <Layout
      title={title}
      description={description.description}
      image={`https:${mainImage.file.url}`}
      article={true}
    >
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray_2"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray_2"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray_2"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-bright_gold_120 font-semibold tracking-wide uppercase">
                {data.contentfulArticle.type}
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-chocolate font-display sm:text-5xl">
                {title}
              </span>
            </h1>
            <GatsbyImage
              image={getImage(mainImage.gatsbyImageData)}
              className="my-16"
            />
          </div>
          <div className="mt-6 prose prose-bright_gold prose-lg text-chocolate mx-auto">
            {body && renderRichText(body, options)}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($pageId: String!, $language: String!) {
    contentfulArticle(id: { eq: $pageId }) {
      description {
        description
      }
      title
      type
      mainImage {
        gatsbyImageData(layout: FULL_WIDTH)
        file {
          url
        }
      }
      additionalImages {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
